* {
  box-sizing: border-box;
}

#root,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  border: none;
  background: #c4c4c4;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #333333;
  border-radius: 3px;
}

@font-face {
  font-family: 'Everett-Jana';
  src: url('./assets/fonts/Everett-Jana.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Everett-Light';
  src: url('./assets/fonts/Everett-Light.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Everett-Medium';
  src: url('./assets/fonts/Everett-Medium.otf'), local('Everett-Medium');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Everett-Regular';
  src: local('Everett-Regular'), url('assets/fonts/Everett-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

.tox-notification.tox-notification--in.tox-notification--warning {
  display: none !important;
}